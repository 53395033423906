import { useEffect, useState } from 'react';
import './css/App.css';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import PremiumPage from './components/Premium';
import ContactPage from './components/Contact';
import PrivacyPage from './components/Privacy';
import HomePage from './components/Home';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCanceled from './components/PaymentCancelled';

function App() { 
    return (
      <div id="RootApplicationContainer">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/premium" element={<PremiumPage />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-canceled" element={<PaymentCanceled />} />
        </Routes>
      </div>
    );
  }

export default App;