import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const [stripe, setStripe] = useState<any | null>(null);
  const [sessionDetails, setSessionDetails] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);
      setStripe(stripeInstance);
    };
    initializeStripe();
  }, []);

  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        if (!sessionId || !stripe) return;
        const session = await stripe.checkout.sessions.retrieve(sessionId);
        setSessionDetails(session);
      } catch (err) {
        setError('Failed to verify payment. Please contact support.');
        console.error('Error fetching session details:', err);
      }
    };

    fetchSessionDetails();
  }, [sessionId, stripe]);

  return (
    <div className="payment-status-container">
      <div className="payment-success">
        <h1>🎉 Payment Successful!</h1>
        {sessionDetails ? (
          <>
            <p>Thank you for your purchase!</p>
            <div className="payment-details">
              <p>Amount: ${(sessionDetails.amount_total! / 100).toFixed(2)}</p>
              <p>Email: {sessionDetails.customer_details?.email}</p>
            </div>
            <a href="/" className="return-home">
              Return to Home
            </a>
          </>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <div className="loading">Verifying your payment...</div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;