import React from 'react';
import { auth, db, provider } from '../firebaseConfig'; // Adjust the path as needed
import { signOut, signInWithPopup } from 'firebase/auth';
import '../css/Header.css';
import '../css/Login.css'
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';

const Header: React.FC = () => {
  const { userData } = useUser();
  const handleLoginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);  
      const user = result.user;
      if (!user) {
        throw new Error("No user information returned from Google login.");
      }  
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <header className="Header-container">
      <nav>
        <Link className="headerNavigationButton" to="/">Home</Link>
        <Link className="headerNavigationButton" to="/contact">Contact Us</Link>
        <Link className="headerNavigationButton" to="/privacy">Privacy</Link>
        <Link className="headerNavigationButton" to="/premium">Premium</Link>
      </nav>
      <div>
        {userData ? (
          <>
            <button className='signOutButton' onClick={handleSignOut}>Sign Out</button>
          </>
        ) : (
          <button className='signInWithGoogleButton' onClick={handleLoginWithGoogle}>Sign in with Google</button>
        )}
      </div>
    </header>
  );
}

export default Header;