import { Link } from 'react-router-dom';

const PaymentCanceled = () => {
  return (
    <div className="payment-status-container">
      <div className="payment-canceled">
        <h1>❌ Payment Canceled</h1>
        <p>Your payment was not completed. You can try again anytime.</p>
        <div className="actions">
          <Link to="/pro" className="retry-payment">
            Try Again
          </Link>
          <Link to="/" className="return-home">
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentCanceled;