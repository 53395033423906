import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { getFirestore, doc, getDoc, setDoc, query, collection, where, getDocs } from 'firebase/firestore';

type UserContextType = {
  userData: any;
};

const UserContext = createContext<UserContextType>({
  userData: null,
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<any>(null);

  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async () => {
      if (auth.currentUser) {
        try {
          // Use a query to find the user document
          const userQuery = query(collection(db, 'users'), where('userID', '==', auth.currentUser.uid));
          const querySnapshot = await getDocs(userQuery);
  
          if (querySnapshot.empty) {
            // No user document found, create a new one
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            await setDoc(userDocRef, {
              firstLogin: new Date(),
              userEmail: auth.currentUser?.email,
              userID: auth.currentUser?.uid,
              paymentStatus: 'Unpaid'
            });
            
            // Re-fetch the user data after creating it
            const userDoc = await getDoc(userDocRef);
            setUserData(userDoc.data());
          } else {
            // User document exists, retrieve and set the data
            const userDoc = querySnapshot.docs[0]; // Only one document should match
            setUserData(userDoc.data());
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUserData(null);
      }
    });
  
    return () => unsubscribe();
  }, [db]);
  
  
  return (
    <UserContext.Provider value={{ userData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
