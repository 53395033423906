import React from 'react';
import '../css/Home.css';
import MusicScale from '../MusicScale';

import { Timestamp } from 'firebase/firestore';
import { useUser } from './UserContext';

function isWithinLast30Days(firstLoginDate: any): boolean {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  let firstLogin: Date;

  // Check if the date is a Firestore Timestamp
  if (firstLoginDate instanceof Timestamp) {
    firstLogin = firstLoginDate.toDate();
  } else {
    // Assume it's a string and convert to Date
    firstLogin = new Date(firstLoginDate);
  }

  return firstLogin >= thirtyDaysAgo;
}

const HomePage: React.FC = () => {
  const { userData } = useUser();

  return (
    <div>
      {userData ? (
        userData.paymentStatus === 'Paid' || isWithinLast30Days(userData.firstLogin) ? (
          <MusicScale />
        ) : (
          <>   
            <h1>Application Access</h1>
            <div className="home-container">
            <p>
                Your application access has expired, please purchase permanent access via the Access tab, or sign in via different email. 
            </p>
            </div>
          </>
        )
      ) : (
        <>   
          <h1>Music Tutor Web Application</h1>
          <div className="home-container">
            <p>
                Please sign in to get started.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default HomePage;